<template>
  <AppBanner :url="require('@/assets/image/cover/about.png')" title="關於仁大" />
  <!-- nav -->
  <AppContentNav v-model="activeId" :items="navItems" />
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeId: "breif",
      lastActiveId: "",
      navItems: [
        { id: "breif", name: "公司簡介", path: "/company?anchor=breif" },
        { id: "win", name: "沿革與獎章", path: "/company?anchor=win" },
        { id: "guarantee", name: "企業認證", path: "/company?anchor=guarantee" },
        { id: "partner", name: "合作夥伴", path: "/partner" },
        { id: "customer", name: "客戶群", path: "/customer" },
        { id: "104", name: "菁英招募", outBrowser: "https://www.104.com.tw/company/7rc6s7s" },
      ],
    };
  },
  watch: {
    activeId(val) {
      if (val === "104") {
        this.activeId = this.lastActiveId || "breif";
      }
      this.lastActiveId = val;
    },
  },
  created() {
    const routeName = this.$route.name;
    if (routeName === "company") {
      const { anchor } = this.$route.query;
      this.activeId = anchor || "breif";
    } else {
      this.activeId = routeName;
    }
  },
};
</script>
